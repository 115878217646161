<template>
  <div class="manual-detail">
    <div class="row p-3">
      <div class="col-2">
        <c-label-text title="교육명" :value="educationInfo.educationName"></c-label-text>
      </div>
      <div class="col-2">
        <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
      </div>
      <div class="col-2">
        <c-label-text title="교육종류1" :value="educationInfo.educationKindCdLargeName"></c-label-text>
      </div>
      <div class="col-2">
        <c-label-text title="교육종류2" :value="educationInfo.educationKindCdSmallName"></c-label-text>
      </div>
      <div class="col-2">
        <c-label-text title="사업장" :value="educationInfo.plantName"></c-label-text>
      </div>
      <div class="col-2">
        <c-label-text title="주관부서" :value="educationInfo.departmentDeptName"></c-label-text>
      </div>
    </div>
    <div class="row vodRow">
      <div class="col-10 vod-label-layer-vod">
        <div class="player-center">
          <video-player ref="videoPlayer"
            :options="playerOptions"
            @playing="onPlayerStart"
            @play="onPlay"
            @pause="onPause"
            @ended="onPlayerEnded($event)">
          </video-player>
        </div>
      </div>
      <div class="col-2 vod-label-layer">
        <div class="vod-label-div" v-if="isEduEnd">이수완료 : {{ isEduEndDate }}</div>
        <div class="vod-label-div" v-else>재생시간 : {{ vodPlayDuration }} / {{ vodTotalDuration }}</div>
        <div class="vod-label-div">
          <c-btn btnclass="full-width" label="처음부터 재생" icon="skip_next" color="orange" @btnClicked="onPlayerPlayFirst" v-if="isFirstPlay && !isPlay && !isEduEnd" />
        </div>
        <!-- 연속재생 -->
        <div class="vod-label-div">
          <c-btn btnclass="full-width" :label="playLabel" :icon="playIcon" :color="playColor" @btnClicked="onPlayerPlay" v-if="!isEduEnd" /> 
        </div>
        <!-- <div class="vod-label-div" v-if="isEnd && !isPlay && !isEduEnd"> 
          <div class="my-3" v-if="educationInfo.isQuestionFlag === 'N' && !isEduQuestionPass">서명/의견은 문제풀이 후 입력할 수 있습니다.</div>
          <c-btn btnclass="full-width" label="문제풀이" icon="task_alt" color="teal-6" @btnClicked="questionExplanation" />
        </div>
        <div class="vod-label-div my-3" v-if="educationInfo.isQuestionFlag === 'Y'">
          획득점수 / 통과기준점수 :  {{ educationInfo.score }} / {{ educationInfo.eduQuestionPassScore }} 점<br/>
          문제풀이 시간 : <span v-if="educationInfo.eqrRegDt"> {{ educationInfo.eqrRegDt.split('.')[0] }} </span>
        </div> -->
        <div v-show="isEnd && educationInfo.checkCompleteFlag!=='Y' && !disabled">
          <div class="container signature-border vod-label-div mt-5">
            <div class="row items-center all-pointer-events">
              <b><font id="signatureTitle">&nbsp;&nbsp;서명</font></b>
            </div>
            <VueSignaturePad id="signature" ref="signaturePad" :options="options"/>
          </div>
          <div class="text-center mt-3">
            <q-btn-group outline >
              <c-btn label="서명지우기" icon="remove" color="red-6" @btnClicked="onSighRemove" />
              <c-btn label="교육이수 완료" icon="task_alt" color="teal-6" @btnClicked="eduComplete" />
            </q-btn-group>
          </div>
          <div class="mt-5">
            <c-textarea
              :disabled="disabled"
              :rows="2"
              label="의견"
              name="opinionContent"
              v-model="educationInfo.opinionContent">
            </c-textarea>
          </div>
        </div>
        <div class="vod-label-div text-center my-3" v-if="disabled">교육완료 또는 이수완료 되었습니다.</div>
        <div class="vod-label-div-text" v-else>
          ※ 재생/정지/완료 버튼을 클릭하지 않고 창을 닫을 경우 동영상 재생이력이 저장되지 않습니다.
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-vod-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    },
    disabled() {
      return this.educationInfo.documentStatusCd === 'ESC000010' || this.educationInfo.checkCompleteFlag === 'Y'
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isEduEnd: false,
      isEduEndDate: '',
      options: {
        penColor: "#000000",
      },
      isEduQuestionPass: true,
      isEnd: false,
      isFirstPlay: true,
      isPost: true,
      isInterval: null,
      playDutaionTime: 0,
      isPlay: false,
      playLabel: '재생',
      playColor: 'blue',
      playIcon: 'play_arrow',
      vodTotalDuration: '',
      vodTotalDurationS: 0,
      vodPlayDuration: '',
      title: '',
      playerOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        fluid: true,  // 비디오 플레이어가 컨테이너에 맞게 크기 조정
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          playToggle: false,
          remainingTimeDisplay: true,
          progressControl: true,
          qualitySelector: true,
          volumePanel: {
            inline: false,  // 볼륨 패널이 컨트롤 바 내에 위치
          },
        },
        // height: (window.innerHeight - 80) + 'px',
      },
      editable: true,
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',
        vod: null,
        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
        eduQuestionFlag: 'N',  // 교육에 문제풀이 포함 여부
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        score: null,
        opinionContent: '',
        electronSignature: '',
        attendeesId: '',
        attendeesNo: '',
        attendanceTime: '',
        checkCompleteFlag: 'N',
        isQuestionFlag: 'N',  // 문제풀이하여 통과 기록 보유 여부
        eqrRegDt: '',
      },
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      updateSignatureUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted () {
    this.init();
  },
  activated() {
  },
  deactivated() {
    clearInterval(this.isInterval);
  },
  beforeDestroy() {
  },
  watch: {
    '$root.$data.windowWidth': function() {
      this.updatePlayerSize();
    },
    '$root.$data.windowHeight': function() {
      this.updatePlayerSize();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.vod.get.url;
      this.insertUrl = transactionConfig.sop.edu.vod.insert.url;
      this.updateUrl = transactionConfig.sop.edu.vod.update.url;
      this.updateSignatureUrl = transactionConfig.sop.edu.plan.update.targetUser.signature.url;
      this.$refs['signaturePad'].signaturePad.canvas.height = 300
      this.$refs['signaturePad'].signaturePad.canvas.width = 300
      this.getDetail();
      window.addEventListener('resize', this.updatePlayerSize);
      this.updatePlayerSize(); // 초기 크기 설정
    },
    updatePlayerSize() {
      const videoContainer = this.$refs.videoPlayer.$el.closest('.col-10');
      const videoPlayer = this.$refs.videoPlayer.$el.querySelector('video');
      const vodRow = document.querySelector('.vodRow')

      if (videoContainer && videoPlayer) {
        videoPlayer.style.width = (videoContainer.clientWidth-15) + 'px';
        videoPlayer.style.height = (window.innerHeight - 120) + 'px'; // 높이는 여전히 고정
        vodRow.style.height = (window.innerHeight - 100) + 'px';
      }
    },
    onSighRemove() {
      this.$refs.signaturePad.clearSignature();
      this.educationInfo.electronSignature = '';
    },
    convertFloat(number) {
      return number ? parseFloat(number) : 0
    },
    getDetail() {
      // 해당 교육용 동영상정보 호출
      this.$http.url = selectConfig.sop.edu.result.get.pcvod.url
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.$route.query.eduEducationId,
        userId: this.$store.getters.user.userId,
      }
      this.$http.request((_result) => {
        this.educationInfo = _result.data;
        if (this.educationInfo.eduQuestionFlag == 'Y') {this.isEduQuestionPass = false;
        } else {this.isEduQuestionPass = true;}
        if (this.educationInfo.electronSignature) {
          this.$refs.signaturePad.fromDataURL(this.educationInfo.electronSignature);}
        if (this.educationInfo.documentStatusCd === 'ESC000010' || this.educationInfo.checkCompleteFlag === 'Y') {
          this.$refs.signaturePad.lockSignaturePad();
          this.isEduQuestionPass = true
        }
        if (this.educationInfo.educationMethodCd == 'EMC999999') {
          var _div = document.getElementsByClassName('mobilevod')[0];
          if (_div) {_div.classList.remove('mobilevod-hide');}
        }

        if (process.env.VUE_APP_UPLOAD_TYPE == 'AWS') {
          // AWS S3용
          this.$http.url = selectConfig.com.upload.vodDownS3.url;
          this.$http.type = 'GET';
          this.$http.isLoading = false;
          this.$http.param = {
            sysAttachFileId: _result.data.vod
          }
          this.$http.request((_result2) => {
            const src = _result2.data
            this.playVideo(src)
            this.getData();
          },);
        } else {
          // DISK 저장용
          const src = process.env.VUE_APP_API_URL + selectConfig.com.upload.vodDown.url + '/' + _result.data.vod;
          this.playVideo(src)
          this.getData();
        }

        if (this.educationInfo.eduQuestionFlag == 'Y') {
          this.isEduQuestionPass = false;
        } else {
          this.isEduQuestionPass = true;
        }
      },);
    },
    getData() {
      if (this.$route.query.eduEducationId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.isLoading = false;
        this.$http.param = {
          eduEducationId: this.$route.query.eduEducationId,
          attendeesId: this.$store.getters.user.userId,
        }
        this.$http.request((_result) => {
          if (_result.data) {
            this.playDutaionTime = this.convertFloat(this.playDutaionTime) + this.convertFloat(_result.data.vodPlayTime);
            this.vodPlayDuration = this.getPlayTime(this.playDutaionTime);
            this.vodTotalDurationS = this.convertFloat(_result.data.vodTotalTime);
            this.vodTotalDuration = this.getPlayTime(this.vodTotalDurationS);
            if (_result.data.vodCurrentTime) {
              this.player.currentTime(this.convertFloat(_result.data.vodCurrentTime))
            }
            this.playLabel = '연속재생'
            this.isPost = false;
            this.isFirstPlay = true;

            if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 90) {
              this.isEnd = true;
            }
            
            if (_result.data.checkCompleteFlag == 'Y') {
              this.isEduEndDate = _result.data.evaluationDate;
              this.isEduEnd = true;
            }
          } else {
            this.isPost = true;
            this.isFirstPlay = false;
          }
        },);
      }
    },
    questionExplanation() {
      if (this.educationInfo.eduQuestionFlag == 'Y') {
        // 문제풀이 대상 교육
        this.openQuiz();
      }
    },
    eduComplete() {
      let data = ''
      if(this.$refs.signaturePad.saveSignature()) data = this.$refs.signaturePad.saveSignature().data;
      this.educationInfo.electronSignature = data; 
      
      if (!this.educationInfo.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '서명하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '교육 이수를 완료하시겠습니까?', 
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateSignatureUrl;
            this.$http.type = 'PUT';
            this.$http.param = {
              electronSignature: this.educationInfo.electronSignature,
              attendeesId: this.$store.getters.user.userId,
              eduEducationId: this.$route.query.eduEducationId,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              checkCompleteFlag: 'Y',
              opinionContent: this.educationInfo.opinionContent,
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.isEduEnd = true;
              this.educationInfo.checkCompleteFlag = 'Y'
              this.getDetail();
              // 부모 창의 리스트를 갱신하는 함수 호출
              if (window.opener && typeof window.opener.getList === 'function') {
                window.opener.getList(); // 부모 창의 리스트를 갱신하는 함수 호출
              } else {console.error('Parent window or getList method not available');}
              // window.close(); // 자식 창 닫기
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    savePlayTime() {
      if (this.isPost) {
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.isPost = false;
      } else {
        this.$http.url = this.updateUrl;
        this.$http.type = 'PUT';
      }
      this.$http.isLoading = false;
      this.$http.param = {
        eduEducationId: this.$route.query.eduEducationId,
        attendeesId: this.$store.getters.user.userId,
        vodTotalTime: this.player.cache_.duration,
        vodPlayTime: this.playDutaionTime,
        vodCurrentTime: this.player.currentTime(),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      }
      this.$http.request(() => {
      },);
    },
    // 실제 플레잉되고 있는 경우 초 증가처리 (Video-player 컴포넌트 playing 이벤트)
    onPlayerStart() {
      clearInterval(this.isInterval);
      this.isInterval = setInterval(() => {
        this.playDutaionTime++;
        this.vodPlayDuration = this.getPlayTime(this.playDutaionTime);
      }, 1000);
    },
    onPlay() {
      this.vodTotalDurationS = this.player.cache_.duration;
      this.vodTotalDuration = this.getPlayTime(this.player.cache_.duration);
      this.player.play();
      this.isPlay = true;
      this.playLabel = '중지'
      this.playColor = 'red';
      this.playIcon = 'pause';
    },
    onPause() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생'
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';

      if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 90) {
        this.isEnd = true;
      }
      // 중지 시 재생시간 저장
      this.savePlayTime();
    },
    onPlayerPlayFirst() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생'
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.isFirstPlay = false;
      this.playDutaionTime = 0;
      this.player.currentTime(0)
      this.onPlayerPlay();
    },
    onPlayerPlay () {
      if (!this.isPlay) {
        this.vodTotalDurationS = this.player.cache_.duration;
        this.vodTotalDuration = this.getPlayTime(this.player.cache_.duration);
        this.player.play();
        this.isPlay = true;
        this.playLabel = '중지'
        this.playColor = 'red';
        this.playIcon = 'pause';
      } else {
        clearInterval(this.isInterval);
        this.player.pause();
        this.isPlay = false;
        this.playLabel = '재생'
        this.playColor = 'blue';
        this.playIcon = 'play_arrow';

        if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 90) {
          this.isEnd = true;
        }
        // 중지 시 재생시간 저장
        this.savePlayTime();
      }
    },
    onPlayerEnded () {
      clearInterval(this.isInterval);
      this.playDutaionTime = 0;
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생'
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.savePlayTime();
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video)
    },
    getPlayTime(_min) {
      let m = parseInt(_min / 60);
      let s = parseInt(_min % 60);
      return m + '분 ' + s + '초'
    },
    openQuiz() {
      this.popupOptions.title = '교육 문제풀이'
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        eduEducationId: this.$route.query.eduEducationId,
        eduQuestionMstId: this.educationInfo.eduQuestionMstId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/quiz/solvingQuiz.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeQuizPopup;
    },
    closeQuizPopup(_type, score) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_type == 'SUCCESS') {
        this.isEduQuestionPass = true;
        this.educationInfo.isQuestionFlag = 'Y';
        if(score) this.educationInfo.score = score
      } else {this.isEduQuestionPass = false;}
      this.getDetail();
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    callback1(data, rowIndex) {
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
  }
};
</script>
<style type="scss">
  .video-js {
    margin: 0 auto !important;
    /* display: contents !important; */
  }
  .player {
    position: absolute !important;
    width: 100%;
    height: 60%;
  }
  .vjs-custom-skin {
    height: 60% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    height: 60%;
  }

  .vjs-big-play-centerd .vjs-big-play-button {
    display: none !important;
  }

  .signature-border {
    border: 1px solid #888888
  }

  .vod-label-div {
    padding-bottom: 10px;
    font-weight: 600;
  }
  .vod-label-div2 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .vod-label-layer {
    border: 1px solid #d8d7d7;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-label-layer-vod {
    border: 1px solid #d8d7d7;
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-top-layer {
    background-color: #2196f3;
    padding: 10px !important;
    font-size: 1.4em;
    font-weight: 700;
    color: #fff;
  }
  .vod-label-div-text {
    margin-top: 10px;
    font-weight: 600;
    font-size: 1em;
    line-height: 24px;
  }
</style>