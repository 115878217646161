var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manual-detail" },
    [
      _c("div", { staticClass: "row p-3" }, [
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: {
                title: "교육명",
                value: _vm.educationInfo.educationName,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: {
                title: "교육과정",
                value: _vm.educationInfo.educationCourseName,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: {
                title: "교육종류1",
                value: _vm.educationInfo.educationKindCdLargeName,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: {
                title: "교육종류2",
                value: _vm.educationInfo.educationKindCdSmallName,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: { title: "사업장", value: _vm.educationInfo.plantName },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("c-label-text", {
              attrs: {
                title: "주관부서",
                value: _vm.educationInfo.departmentDeptName,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row vodRow" }, [
        _c("div", { staticClass: "col-10 vod-label-layer-vod" }, [
          _c(
            "div",
            { staticClass: "player-center" },
            [
              _c("video-player", {
                ref: "videoPlayer",
                attrs: { options: _vm.playerOptions },
                on: {
                  playing: _vm.onPlayerStart,
                  play: _vm.onPlay,
                  pause: _vm.onPause,
                  ended: function ($event) {
                    return _vm.onPlayerEnded($event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-2 vod-label-layer" }, [
          _vm.isEduEnd
            ? _c("div", { staticClass: "vod-label-div" }, [
                _vm._v("이수완료 : " + _vm._s(_vm.isEduEndDate)),
              ])
            : _c("div", { staticClass: "vod-label-div" }, [
                _vm._v(
                  "재생시간 : " +
                    _vm._s(_vm.vodPlayDuration) +
                    " / " +
                    _vm._s(_vm.vodTotalDuration)
                ),
              ]),
          _c(
            "div",
            { staticClass: "vod-label-div" },
            [
              _vm.isFirstPlay && !_vm.isPlay && !_vm.isEduEnd
                ? _c("c-btn", {
                    attrs: {
                      btnclass: "full-width",
                      label: "처음부터 재생",
                      icon: "skip_next",
                      color: "orange",
                    },
                    on: { btnClicked: _vm.onPlayerPlayFirst },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vod-label-div" },
            [
              !_vm.isEduEnd
                ? _c("c-btn", {
                    attrs: {
                      btnclass: "full-width",
                      label: _vm.playLabel,
                      icon: _vm.playIcon,
                      color: _vm.playColor,
                    },
                    on: { btnClicked: _vm.onPlayerPlay },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isEnd &&
                    _vm.educationInfo.checkCompleteFlag !== "Y" &&
                    !_vm.disabled,
                  expression:
                    "isEnd && educationInfo.checkCompleteFlag!=='Y' && !disabled",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass: "container signature-border vod-label-div mt-5",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row items-center all-pointer-events" },
                    [
                      _c(
                        "b",
                        [
                          _c("font", { attrs: { id: "signatureTitle" } }, [
                            _vm._v("  서명"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("VueSignaturePad", {
                    ref: "signaturePad",
                    attrs: { id: "signature", options: _vm.options },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center mt-3" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: {
                          label: "서명지우기",
                          icon: "remove",
                          color: "red-6",
                        },
                        on: { btnClicked: _vm.onSighRemove },
                      }),
                      _c("c-btn", {
                        attrs: {
                          label: "교육이수 완료",
                          icon: "task_alt",
                          color: "teal-6",
                        },
                        on: { btnClicked: _vm.eduComplete },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c("c-textarea", {
                    attrs: {
                      disabled: _vm.disabled,
                      rows: 2,
                      label: "의견",
                      name: "opinionContent",
                    },
                    model: {
                      value: _vm.educationInfo.opinionContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.educationInfo, "opinionContent", $$v)
                      },
                      expression: "educationInfo.opinionContent",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.disabled
            ? _c("div", { staticClass: "vod-label-div text-center my-3" }, [
                _vm._v("교육완료 또는 이수완료 되었습니다."),
              ])
            : _c("div", { staticClass: "vod-label-div-text" }, [
                _vm._v(
                  " ※ 재생/정지/완료 버튼을 클릭하지 않고 창을 닫을 경우 동영상 재생이력이 저장되지 않습니다. "
                ),
              ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }